




















import isEqualBy from '@/tools/is-equal-by'

import FunctionForm from '@/components/shared/entities/function-form.vue'

export default {
  name: 'function-properties',
  props: ['value', 'error'],
  components: {
    'function-form': FunctionForm,
  },
  data() {
    return {
      modifiedFunction: this.value,
      oFunction: this.value,
    }
  },
  mounted() {
    this.focusNameInput()
  },
  methods: {
    focusNameInput() {
      this.$nextTick(() => this.$refs.functionForm.focus())
    },
    onInput() {
      this.$emit('input', this.modifiedFunction)
    },
    applyFunctionChange() {
      this.$emit('apply')
    },
    resetFunctionChange() {
      this.modifiedFunction = this.oFunction
    },
  },
  computed: {
    functionEdited() {
      return !isEqualBy(this.modifiedFunction, this.oFunction, ['name', 'definition', 'annotation'])
    },
  },
  watch: {
    value(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.modifiedFunction = val
        this.oFunction = val
        this.focusNameInput()
      }
    },
  },
}
